import Galeria from "./Galeria";
import "./Oferta.css";
import PlanEstudios from "./PlanEstudios";

function Oferta() {
  return (
    <div className="oferta">
      <PlanEstudios />
      <Galeria />
    </div>
  );
}

export default Oferta;
