import React, { useState } from "react";
import emailjs from "emailjs-com";
import "./ContactoForm.css";

function ContactoForm() {
  const [formData, setFormData] = useState({
    nombre: "",
    email: "",
    tel: "",
    asunto: "",
    mensaje: ""
  });

  const enviarEmail = async (e) => {
    e.preventDefault();

    try {
      const res = await emailjs.sendForm(
        "service_4rj4yy9",
        "template_y7d2ymi",
        e.target,
        "yuYFK8v6YB2zPCXBa"
      );

      if (res.status === 200) {
        alert("Se ha enviado correctamente.");
        console.log(res);
        limpiarFormulario();
      }
    } catch (error) {
      console.error("Error al enviar el correo", error);
      alert("Hubo un error al enviar el correo");
    }
  };

  const limpiarFormulario = () => {
    setFormData({
      nombre: "",
      email: "",
      tel: "",
      asunto: "",
      mensaje: ""
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  return (
    <div className="form-container">
      <h1>¿Tienes alguna pregunta o comentario?</h1>
      <form onSubmit={enviarEmail}>
        <input
          name="nombre"
          id="nombre"
          placeholder="Nombre"
          type="text"
          value={formData.nombre}
          onChange={handleInputChange}
        />
        <input
          name="email"
          id="email"
          type="email"
          placeholder="Correo electrónico"
          value={formData.email}
          onChange={handleInputChange}
        />
        <input
          name="tel"
          id="tel"
          type="tel"
          placeholder="Teléfono"
          value={formData.tel}
          onChange={handleInputChange}
        />
        <input
          name="asunto"
          id="asunto"
          placeholder="Asunto"
          value={formData.asunto}
          onChange={handleInputChange}
        />
        <textarea
          name="mensaje"
          id="mensaje"
          placeholder="Tu mensaje"
          rows="4"
          value={formData.mensaje}
          onChange={handleInputChange}
        ></textarea>
        <button type="submit">Enviar</button>
      </form>
    </div>
  );
}

export default ContactoForm;
