import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Nosotros from "../components/Nosotros";
import ImgHero from "../assets/IMG_5003.JPG"

function About() {
  return (
    <>
      <Navbar />
      <Hero
        cName="hero-mid"
        heroImg={ImgHero}
        title="CONÓCENOS"
      />
      <Nosotros />

      <Footer />
    </>
  );
}

export default About;
