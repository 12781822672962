import "./Galeria.css";
import GaleriaData from "./GaleriaData";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Img1 from "../assets/366165573_693167535955655_7842308640530808141_n.jpg";
import Img2 from "../assets/366278908_693170442622031_3141440541557727474_n.jpg";
import Img3 from "../assets/354032475_665109135428162_7035762412944811047_n.jpg";
import Img4 from "../assets/353755981_665107818761627_2361579822922471336_n.jpg";
import Img5 from "../assets/304770372_497710298834714_7418174111502247364_n.jpg";
import Img6 from "../assets/308647710_515412727064471_2196431914673016602_n.jpg";
import Img7 from "../assets/307324406_515412750397802_4729299447551090288_n.jpg";
import Img8 from "../assets/310270620_515412820397795_3962838182801816765_n.jpg";
import Img9 from "../assets/338392934_1178237646226139_369349000900619293_n.jpg";

function Galeria() {
  return (
    <div className="galeria">
      <hr />
      <div className="galeriacard">
        <GaleriaData image={Img1} />
        <GaleriaData image={Img2} />
        <GaleriaData image={Img3} />
        <GaleriaData image={Img4} />
        <GaleriaData image={Img5} />
        <GaleriaData image={Img6} />
        <GaleriaData image={Img7} />
        <GaleriaData image={Img8} />
        <GaleriaData image={Img9} />
      </div>
      <div className="g-carousel">
      <Carousel autoPlay interval={3000} infiniteLoop>
          <div>
            <img
              src={Img1}
              alt=""
            />
          </div>
          <div>
            <img
              src={Img2}
              alt=""
            />
          </div>
          <div>
            <img
              src={Img3}
              alt=""
            />
          </div>
          <div>
            <img
              src={Img4}
              alt=""
            />
          </div>
          <div>
            <img
              src={Img5}
              alt=""
            />
          </div>
          <div>
            <img
              src={Img6}
              alt=""
            />
          </div>
          <div>
            <img
              src={Img7}
              alt=""
            />
          </div>
          <div>
            <img
              src={Img8}
              alt=""
            />
          </div>
        </Carousel>
      </div>
    </div>
  );
}

export default Galeria;
