import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import Contacto from "../components/Contacto";
import ImgHero from "../assets/IMG_5003.JPG"

function Contact() {
  return (
    <>
      <Navbar />
      <Hero
        cName="hero-mid"
        heroImg={ImgHero}
        title="CONTÁCTANOS"
      />
      <Contacto />
      <Footer />
    </>
  );
}

export default Contact;
