import "./Evento.css";
import EventoData from "./EventoData";
import Evento1 from "../assets/366208299_693167495955659_3354722244600429683_n.jpg";
import Evento2 from "../assets/358423680_873609667672066_7768078147625249087_n.jpg";
import Evento3 from "../assets/356124045_670005491605193_3088032581649494311_n.jpg";

function Evento() {
  return (
    <div className="evento">
      <h2>Eventos</h2>
      <hr />
      <div className="eventocard">
        <EventoData
          image={Evento1}
          heading="Prep. Celia Lechón Noyola Oficial presente en la FENAPO"
          text="Estamos ubicados en el pabellón industrial, estand 28 ¡Visítanos!"
        />
        <EventoData
          image={Evento2}
          heading="¡Agradecemos al Lic. Enrique Galindo Ceballo por el apoyo otorgado a nuestra institución!"
          text="¡Estamos emocionados de apoyar el talento musical de las y los jóvenes potosinos!
          Los instrumentos entregados a la Rondalla Estudiantil Linces de la Escuela Preparatoria Celia Lechón serán una herramienta invaluable para su desarrollo artístico. 
          ¡Que sigan creando música y dejando huella en cada nota!"
        />
        <EventoData
          image={Evento3}
          heading="Graduación 
          XX Generación 2021-2023"
          text="Se despiden con honores nuestros alumnos de la XX Generación."
        />
      </div>
    </div>
  );
}

export default Evento;
