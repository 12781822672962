import "./Tablas.css";

function TablaMatE() {
  return (
    <table className="mat-extra">
      <thead>
        <tr>
          <th>Formación Cultural y Artística</th>
          <th>Promoción de Salud y el Deporte</th>
          <th>Desarrollo de Habilidades del Pensamiento</th>
        </tr>
      </thead>
    </table>
  );
}

export default TablaMatE;
