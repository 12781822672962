import { Component } from "react";
import "./Navbar.css";
import { MenuItems } from "./MenuItems";
import { Link } from "react-router-dom";
import Logo from "../assets/logo-cln.png"

class Navbar extends Component {
  state = { clicked: false };
  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };

  handleClick2 = () => {
    // Redirige a un enlace cuando se hace clic en el botón
    window.location.href = "https://celialechonnoyola.edu.mx/aulanet/login/index.php";
  }
  
  handleScrollToTop = () => {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <nav className="NavbarItems">
        <h1 className="navbar-logo">
          <img
            className="img-logo"
            src={Logo}
            alt=""
          />
        </h1>

        <div className="menu-icons" onClick={this.handleClick}>
          <i
            className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}
          ></i>
        </div>

        <ul className={this.state.clicked ? "nav-menu active" : "nav-menu"}>
          {MenuItems.map((item, index) => {
            return (
              <li key={index}>
                <Link className={item.cName} to={item.url} onClick={this.handleScrollToTop}>
                  {item.title}
                </Link>
              </li>
            );
          })}
          <button onClick={this.handleClick2}>AulaNet</button>
        </ul>
      </nav>
    );
  }
}

export default Navbar;
