import "./Tablas.css";

function TablaTaller() {
  const data1 = [
    {
      taller: "Talleres Deportivos",
      actividades: ["Futbol", "Basquetbol", "Atletismo", "Voleibol", ""]
    },
    {
      taller: "Talleres Culturales",
      actividades: [
        "Declamación",
        "Oratoria",
        "Rondalla",
        "Banda de Guerra",
        "Estudiantina"
      ]
    }
  ];

  const talleres = data1.map((item) => item.taller);

  return (
    <table className="talleres">
      <thead>
        <tr>
          {talleres.map((taller, index) => (
            <th key={index}>{taller}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data1[0].actividades.map((actividad, indexAct) => (
          <tr key={indexAct}>
            {data1.map((item) => (
              <td key={item.taller}>{item.actividades[indexAct] || "-"}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default TablaTaller;
