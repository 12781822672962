import "./Footer.css";
import Seer from "../assets/logo_seer.png";
import { Link } from "react-router-dom";

const Footer = () => {
  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
};
  return (
    <div className="footer">
      <div className="top">
        <div>
          <h1>CLN</h1>
          <hr />
          <p></p>
        </div>
        <div>
          <a href="https://www.facebook.com/LechonNoyolaOficial">
            <i className="fa-brands fa-facebook-square"></i>
          </a>
          <a href="https://www.google.com/maps/dir/?api=1&destination=22.124717881523%2C-100.93373318481&fbclid=IwAR3GO-JHkbC3q-_XBpMGgL8y0qLuvziPFoTsK3aCUvfMqbcSGnnUkyIHZAw">
            <i className="fa-solid fa-location-dot"></i>
          </a>
        </div>
      </div>
      <div className="bottom">
        <div>
          <h4>Comunidad</h4>
          <Link to="/" onClick={handleScrollToTop}>Estudiantes</Link>
          <Link to="/" onClick={handleScrollToTop}>Docentes</Link>
          <Link to="/" onClick={handleScrollToTop}>Administrativos</Link>
        </div>
        <div>
          <h4>Centro Educativo</h4>
          <a href="https://www.google.com/maps/dir/?api=1&destination=22.124717881523%2C-100.93373318481&fbclid=IwAR3GO-JHkbC3q-_XBpMGgL8y0qLuvziPFoTsK3aCUvfMqbcSGnnUkyIHZAw">Calcita S/N, Fracc. El Bosquecito, CP 78395</a>
          <p>Tel. +52 (444) 842 2199</p>
        </div>
        <div>
          <h4>Enlaces</h4>
          <Link to="/conocenos" onClick={handleScrollToTop}>
          Cónocenos
          </Link>
          <Link to="/oferta-academica" onClick={handleScrollToTop}>Oferta Académica</Link>
          <Link to="/contacto" onClick={handleScrollToTop}>Contacto</Link>
          <Link to="https://celialechonnoyola.edu.mx/aulanet/login/index.php">AulaNet</Link>
        </div>
        <div>
          <img src={Seer} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Footer;
