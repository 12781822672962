import "./Estudiantes.css";
import asset from "../assets/imgdoc.png";
import { Link } from "react-router-dom";

function Estudiantes() {
  return (
    <div className="estudiantes-container">
        <h1>Acuerdos de sana convivencia y carta responsiva</h1>
        <hr />
        <p>Estamos complacidos de recibirte,  a fin de garantizar una administración eficiente y mantener sus registros actualizados, es necesario que descarguen, completen y entreguen los siguientes documentos a la Secretaría Escolar.

Estos documentos son esenciales para diversos trámites internos y para asegurar que su experiencia en nuestra institución sea lo más fluida y beneficiosa posible.</p>
        <div className="estudiantes">
        <div className="a-card">
          <div className="a-image">
            <Link to="https://drive.google.com/file/d/11vtGMrjlscXosNh1X6OWeuBAfPJAD_xv/view?usp=drive_link" target="_blank">
            <img src={asset} alt="" />
            </Link>
          </div>
          <h4>Acuerdos de sana convivencia</h4>
        </div>
        <div className="a-card">
          <div className="a-image">
            <Link to="https://drive.google.com/file/d/15UQY2UZ4C-tds5a6zOt0oD-bmOt-JvV5/view?usp=drive_link" target="_blank">
            <img src={asset} alt="" />
            </Link>
          </div>
          <h4>Carta responsiva</h4>
        </div>
        <div className="a-card">
          <div className="a-image">
            <Link to="https://drive.google.com/file/d/1OOI5xBtqpjy-UaVQW__prjEMN6H-KgBS/view?usp=drive_link" target="_blank">
            <img src={asset} alt="" />
            </Link>
          </div>
          <h4>Hoja de datos personales</h4>
        </div>
      </div>
    </div>
  );
}

export default Estudiantes;
