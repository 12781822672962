import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import Oferta from "../components/Oferta";
import ImgHero from "../assets/IMG_5003.JPG"

function Offer() {
  return (
    <>
      <Navbar />
      <Hero
        cName="hero-mid"
        heroImg={ImgHero}
        title="OFERTA ACADÉMICA"
      />
      <Oferta />
      <Footer />
    </>
  );
}

export default Offer;
