import "./PlanEstudios.css"; // Importa los estilos CSS
import React, { useState } from "react";
import TablaPlanE from "./TablaPlanE";
import TablaMatE from "./TablaMatE";
import TablaTaller from "./TablaTaller";

function PlanEstudios() {
  const [activeTabla, setActiveTabla] = useState(1);

  return (
    <div className="plan-container">
      <nav className="vertical-menu">
        <ul>
          <li>
            <a
              href="#Tabla1"
              onClick={() => setActiveTabla(1)}
              className={activeTabla === 1 ? "active" : ""}
            >
              Plan de Estudios
            </a>
          </li>
          <li>
            <a
              href="#Tabla2"
              onClick={() => setActiveTabla(2)}
              className={activeTabla === 2 ? "active" : ""}
            >
              Extra Escolares
            </a>
          </li>
          <li>
            <a
              href="#Tabla3"
              onClick={() => setActiveTabla(3)}
              className={activeTabla === 3 ? "active" : ""}
            >
              Talleres
            </a>
          </li>
        </ul>
      </nav>

      <div className="tablas">
        {activeTabla === 1 ? <TablaPlanE /> : null}
        {activeTabla === 2 ? <TablaMatE /> : null}
        {activeTabla === 3 ? <TablaTaller /> : null}
      </div>
    </div>
  );
}

export default PlanEstudios;
