import Avisos from "../components/Avisos";
import Evento from "../components/Evento";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Lema from "../components/Lema";
import Navbar from "../components/Navbar";
import ImgHero from "../assets/IMG_5003.JPG"
import Acuerdos from '../assets/Acuerdos de sana convivencia 2024.pdf'
import Carta from '../assets/Carta responsiva 2024.pdf'
import DatosPersonales from '../assets/Hoja de datos personales 2024.pdf'

import Comunidad from "../components/Comunidad";

function Home() {
  return (
    <>
      <Navbar />
      <Hero
        cName="hero"
        heroImg={ImgHero}
        title="PROFRA. CELIA LECHÓN NOYOLA"
        subtitle="ESCUELA PREPARATORIA"
      />

      <Lema />

      <div className="section-docs">
        <a href={Acuerdos} target="_blank" className="item-docs">
          <div>
            Acuerdos de sana convivencia 2024 
          </div>
          <svg width="1.3em" height="1.3em" viewBox="0 0 24 24"><path fill="currentColor" d="M18.22 20.75H5.78A2.64 2.64 0 0 1 3.25 18v-3a.75.75 0 0 1 1.5 0v3a1.16 1.16 0 0 0 1 1.25h12.47a1.16 1.16 0 0 0 1-1.25v-3a.75.75 0 0 1 1.5 0v3a2.64 2.64 0 0 1-2.5 2.75"/><path fill="currentColor" d="M12 15.75a.74.74 0 0 1-.53-.22l-4-4a.75.75 0 0 1 1.06-1.06L12 13.94l3.47-3.47a.75.75 0 0 1 1.06 1.06l-4 4a.74.74 0 0 1-.53.22"/><path fill="currentColor" d="M12 15.75a.76.76 0 0 1-.75-.75V4a.75.75 0 0 1 1.5 0v11a.76.76 0 0 1-.75.75"/></svg>
        </a>
        <a href={Carta} target="_blank" className="item-docs">
          <div>
            Carta responsiva 2024
          </div>
          <svg width="1.3em" height="1.3em" viewBox="0 0 24 24"><path fill="currentColor" d="M18.22 20.75H5.78A2.64 2.64 0 0 1 3.25 18v-3a.75.75 0 0 1 1.5 0v3a1.16 1.16 0 0 0 1 1.25h12.47a1.16 1.16 0 0 0 1-1.25v-3a.75.75 0 0 1 1.5 0v3a2.64 2.64 0 0 1-2.5 2.75"/><path fill="currentColor" d="M12 15.75a.74.74 0 0 1-.53-.22l-4-4a.75.75 0 0 1 1.06-1.06L12 13.94l3.47-3.47a.75.75 0 0 1 1.06 1.06l-4 4a.74.74 0 0 1-.53.22"/><path fill="currentColor" d="M12 15.75a.76.76 0 0 1-.75-.75V4a.75.75 0 0 1 1.5 0v11a.76.76 0 0 1-.75.75"/></svg>
        </a>
        <a href={DatosPersonales} target="_blank" className="item-docs">
          <div>
            Hoja de datos personales
          </div>
          <svg width="1.3em" height="1.3em" viewBox="0 0 24 24"><path fill="currentColor" d="M18.22 20.75H5.78A2.64 2.64 0 0 1 3.25 18v-3a.75.75 0 0 1 1.5 0v3a1.16 1.16 0 0 0 1 1.25h12.47a1.16 1.16 0 0 0 1-1.25v-3a.75.75 0 0 1 1.5 0v3a2.64 2.64 0 0 1-2.5 2.75"/><path fill="currentColor" d="M12 15.75a.74.74 0 0 1-.53-.22l-4-4a.75.75 0 0 1 1.06-1.06L12 13.94l3.47-3.47a.75.75 0 0 1 1.06 1.06l-4 4a.74.74 0 0 1-.53.22"/><path fill="currentColor" d="M12 15.75a.76.76 0 0 1-.75-.75V4a.75.75 0 0 1 1.5 0v11a.76.76 0 0 1-.75.75"/></svg>
        </a>
      </div>

      <Avisos />

      <Evento />
      <Footer />
    </>
  );
}

export default Home;
