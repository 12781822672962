import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ImgHero from "../assets/IMG_5003.JPG"
import Estudiantes from "../components/Estudiantes";

function Alumnos() {
  return (
    <>
      <Navbar />
      <Hero
        cName="hero-mid"
        heroImg={ImgHero}
        title="ESTUDIANTES"
      />
      <Estudiantes />

      <Footer />
    </>
  );
}

export default Alumnos;
