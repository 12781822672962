import "./Nosotros.css";
import Valor1 from "../assets/project_8567429.png";
import Valor2 from "../assets/help_2634501.png";
import Valor3 from "../assets/speed_3395016.png";
import Valor4 from "../assets/justice-scale_3122246.png";

function Nosotros() {
  return (
    <div className="about-container">
      <div className="historia">
        <h1>Nuestra Historia</h1>
        <p>
          La Escuela Prep. Profra. Celia Lechón Noyola perteneciente al Sistema
          Educativo Estatal Regular S.E.E.R; en sus inicios abre sus puertas con
          turno vespertino, contando con 2 grupos de primer año, actualmente
          cuenta con 375 alumnos de segundo y cuarto semestre; distribuido en 9
          grupos, con turno matutino, tiene como propósito desarrollar en sus
          estudiantes habilidades, destrezas, actitudes, valores que le permitan
          resolver problemas de manera analítica y crítica de la vida cotidiana,
          científica y tecnológica. Lo anterior cimentado las bases de un
          aprendizaje formal, en las diversas áreas que cubren los planes de
          estudios vigentes de la U.A.S.L.P.
        </p>
      </div>
      <div className="valores">
        <h1>Nuestros Valores</h1>
        <div className="valor-container">
          <div className="valor">
            <img src={Valor1} alt="" />
            <p>Responsabilidad</p>
          </div>
          <div className="valor">
            <img src={Valor2} alt="" />
            <p>Solidaridad</p>
          </div>
          <div className="valor">
            <img src={Valor3} alt="" />
            <p>Disciplina</p>
          </div>
          <div className="valor">
            <img src={Valor4} alt="" />
            <p>Justicia</p>
          </div>
        </div>
        <hr />
      </div>
      <div className="mision">
        <h1>Misión</h1>
        <p>
          Ofrecer a la sociedad una institución de educación media superior de
          calidad mediante la capacitación permanente del personal, asegurando
          la formación de jóvenes estudiantes capaces de enfrentar eficazmente
          los retos y responsabilidades de la sociedad con un alto sentido de
          ética y valores.
        </p>
      </div>

      <div className="vision">
        <h1>Visión</h1>
        <p>
          Ser una institución educativa con un alto nivel académico reconocida
          socialmente para formar estudiantes y ciudadanos preparados para
          desenvolverse dentro de nuestra sociedad, comprometidos con el
          desarrollo de la comunidad.
        </p>
      </div>
    </div>
  );
}

export default Nosotros;
