import "./Tablas.css";

function TablaPlanE() {
  const data = [
    {
      semestre: "Semestre I",
      materias: [
        "Matemáticas I",
        "Química I",
        "Geografía",
        "Intr. a las Cs. Sociales",
        "Taller de Lectura y Redacción I",
        "Informática I",
        "Inglés I",
        "Etimologías",
        "Orientación Escolar"
      ]
    },
    {
      semestre: "Semestre II",
      materias: [
        "Matemáticas II",
        "Química II",
        "Biología I",
        "Historia de México I",
        "Ética y Valores I",
        "Taller de Lectura y Redacción II",
        "Informática II",
        "Inglés II",
        "Orientación Psicosocial"
      ]
    },
    {
      semestre: "Semestre III",
      materias: [
        "Matemáticas III",
        "Física I",
        "Biología II",
        "Historia de México II",
        "Ética y Valores II",
        "Literatura I",
        "Inglés III",
        "Filosofía",
        "Orientación Vocacional"
      ]
    },
    {
      semestre: "Semestre IV",
      materias: [
        "Matemáticas IV",
        "Física II",
        "Ecología",
        "Estrc. Socioeconómica de Méx.",
        "Hist. Universal Contemporánea",
        "Literatura II",
        "Inglés IV",
        "Metodología de la Investigación",
        "Orientación Profesiográfica y Toma de Decisiones"
      ]
    }
    // ...otros semestres y materias...
  ];

  const semestres = data.map((item) => item.semestre);

  return (
    <table className="plan-estudios">
      <thead>
        <tr>
          {semestres.map((semestre, index) => (
            <th key={index}>{semestre}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data[0].materias.map((materia, indexMateria) => (
          <tr key={indexMateria}>
            {data.map((item) => (
              <td key={item.semestre}>{item.materias[indexMateria] || "-"}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default TablaPlanE;
