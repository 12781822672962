import "./Contacto.css";
import ContactoForm from "./ContactoForm";

function Contacto() {
  return (
    <div className="contacto">
      <ContactoForm />
      <div className="datos-contacto">
        <h2>Administración General</h2>
        <p>
          Para cualquier duda o aclaración, estamos a sus órdenes de lunes a
          viernes de 7:00 a 14:00 horas.
        </p>
        <h2>Dirección</h2>
        <p>
          Calcita S/N, Fracc. El Bosquecito, CP 78395, San Luis Potosí, México
        </p>
        <h2>Teléfono</h2>
        <p>+52 (444) 842 2199</p>
        <h2>Correo Electrónico</h2>
        <p>secretaria.escolar@celialechonnoyola.edu.mx</p>
      </div>
    </div>
  );
}

export default Contacto;
