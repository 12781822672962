import "./Galeria.css";

function GaleriaData(props) {
  return (
    <div className="g-card">
      <div className="g-image">
        <img src={props.image} alt="" />
      </div>
    </div>
  );
}

export default GaleriaData;
