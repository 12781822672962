import "./Avisos.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Calendario from "../assets/Calendario Escolar 2023-2024-descargable_page-0001.jpg";
import Aviso1  from "../assets/a13-08-23-1.png";
import Aviso2  from "../assets/a13-08-23-2.png";
import Aviso3  from "../assets/POSTCLN.png";

function Avisos() {
  return (
    <div className="aviso">
      <div className="calendario">
        <h2>Calendario</h2>
        <hr />
        <a href="https://drive.google.com/file/d/1EbGofrkdfiNpVeZOPI9XQfxYzr-omsTf/view">
          <img src={Calendario} alt="" />
        </a>
      </div>

      <div className="avisos">
        <h2>Avisos</h2>
        <hr />
        <Carousel autoPlay interval={3000} infiniteLoop>
          <a href="https://teams.microsoft.com/l/meetup-join/19:meeting_ODMyMDY1ZTctNWM0Ny00NDNjLWEzZjYtYzA5MGIwZWFkMWM3@thread.v2/0?context=%7B%22Tid%22:%22c1fedaec-f51a-42e9-8cb1-813e6a38823d%22,%22Oid%22:%22f02cb3c0-35a6-4ee2-8530-5dbe35d92b0f%22%7D" target="_blank">
          <div>
            <img
              src={Aviso3}
              alt="Aviso 1"
            />
          </div>
          </a>
          <div>
            <img src={Aviso1} alt="Aviso 2" />
          </div>
        </Carousel>
      </div>
    </div>
  );
}

export default Avisos;
